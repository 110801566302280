ol {
  list-style: none;
  counter-reset: item;
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  padding-left: 10px;
 }

 ol > li {
  counter-increment: item;
  margin-bottom: 20px;
 }

ol > li:before {
  margin-right: 10px;
  content: counter(item);
  background: rgb(246, 246, 247);
  border-radius: 100%;
  width: 2em;
  height: 2em;
  text-align: center;
  display: inline-block;
  border: 1px solid #ed1c24;
  color: #ed1c24;
 }