.support-container {
	position: fixed;
	width: 45px;
	height: 45px;
	bottom: 20px;
	right: 20px;
	background-color: #ed1c24;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
	border: 0;
}