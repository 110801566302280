.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 5px;
}

.grid-item {
  padding: 10px;
  text-align: left;
}

.Polaris-Badge {
  font-size: 1rem;
  padding: 0.25rem 1rem;
}

.Polaris-ProgressBar {
  width: 65%
}

/* 
.Polaris-Card {
  width: 75%;
  margin: auto;
} */